import React from 'react';

import Layout from '../components/layout';
import Docs from '../components/docs';
import SEO from '../components/seo';

const mainURL =
  'https://wp-api.proff86.ru/wp-json/wp/v2/main_docs/?per_page=99';
const orderURL =
  'https://wp-api.proff86.ru/wp-json/wp/v2/order_docs/?per_page=99';

function Documents() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Документы образовательной организации`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Документы'
      />

      <section className='flex flex-col items-center '>
        <h1 className='text-4xl uppercase tracking-wider '>Документы</h1>
        <Docs url={mainURL} title='Основные' />
        <Docs url={orderURL} title='Заявки' />
      </section>
    </Layout>
  );
}

export default Documents;
